const sortAndFilter = (type, facets) => {
  switch (type) {
    case 'version':
      facets.sort();
      return (
        facets.includes('unversioned')
          ? [ 'unversioned', ...facets.slice(0,-1).reverse()  ]
          : facets.reverse()
      )

    default:
      return facets
  }
}

const extractFacets = (fields, facets) => {
  const newFacets = {};

  // Sort and update states
  facets.forEach(facet => {
    if (!fields[facet]) return;

    const values = {};

    Object.keys(fields[facet]).sort().forEach(key => {
      values[key] = fields[facet][key];
    });

    newFacets[facet] = values;
  });

  return newFacets
}

export { sortAndFilter, extractFacets }
