import React from 'react'

const observerOptions = {
  root: null, // relative to document viewport
  rootMargin: '0px', // margin around root. Values are similar to css property. Unitless values not allowed
  threshold: 0.1 // visible amount of item shown in relation to root
};

const ObserveVisibility = ({children, onVisible  = () => false, onHidden = () => false, debug=false}) => {

  const node = React.useRef()
  const visible = React.useRef(undefined)

  // Lazy loading
  React.useEffect(() => {
    const log = (...message) => debug !== false && console.log('[ ObserveVisibility ] ', ...message)

    const onChange = (changes) => {
      changes.forEach((change) => {
        // First time, just set inital state
        if (visible.current === undefined) {
          visible.current = change.isIntersecting
          log('Setting initial state to', change.isIntersecting)
          // Trigger onHidden or onVisible
          if (!visible.current) onHidden()
          else onVisible()
          return
        }
        // Nothing todo
        if (visible.current === change.isIntersecting) {
          return
        }
        // Change to visible
        if (change.isIntersecting) {
          if (onVisible) onVisible()
          log('Became visible')
          visible.current = true
        } else {
          if (onHidden) onHidden()
          log('Became hidden')
          visible.current = false
        }
      })
    }
    const observer = new IntersectionObserver(onChange, observerOptions)
    const target = node.current
    observer.observe(target)

    return () => observer.unobserve(target)
  }, [node, onHidden, onVisible, debug])

  return (
    <div ref={node}>{children}</div>
  )

}

export default ObserveVisibility