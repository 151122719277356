


const getBook = (path) => {
  if (!path || path.length===0) {
    return []
  }
  const regex = /\/([a-zA-Z0-9-]+)\/content\//;
  const match = path.match(regex)
  if (match && match[1]) {
    return match[1]
  } else {
    return ""
  }
}


const calculateManualVersions = (path, versions) => {
  if (!versions) {
    return []
  }
  if (!path || path.length===0) {
    return []
  }
  path=path.toLowerCase()
  if (path.includes("webhelp")) {
    const match = path.match(/artifacts\/([^/]+)\//)
    if (match && match[1]) {
      var version = match[1]
    }
    return [version]
  } else {
    return []
  }
}



const allReleaseVersions = (data, majorRelease) => {
  let jsonArray= []
  jsonArray = data.edges.reduce((acc, curr) => {
    curr.node.amp.forEach((amp) => acc.push( curr.node.aep.version + '.' + amp.version ))
  return acc;
  },jsonArray)

return jsonArray;
}

const getAllValues = (data, cloudProperty, cloudValue, property) => {
  const uniqueComponentsObject = data.edges.reduce((acc, item) => {
    if (item.node.frontmatter && item.node.frontmatter[property] && item.node.frontmatter[cloudProperty] === cloudValue) {
      item.node.frontmatter[property].split(',').forEach((version) => {
        version = version.trim();  // Trim whitespace
        acc[version] = version;    // Use an object to store unique values
      });
    }
    return acc;
  }, {});

  let sortedEntries = [];
  if (property === 'version') {
    sortedEntries = Object.keys(uniqueComponentsObject).sort((a, b) => {
      const aParts = a.split('.').map(Number);
      const bParts = b.split('.').map(Number);

      for (let i = 0; i < Math.max(aParts.length, bParts.length); i++) {
        const aPart = aParts[i] !== undefined ? aParts[i] : 0;
        const bPart = bParts[i] !== undefined ? bParts[i] : 0;
        if (aPart !== bPart) {
          return bPart - aPart;  // Sort descending
        }
      }
      return 0;
    });
  } else {
    sortedEntries = Object.keys(uniqueComponentsObject).sort();
  }
  const sortedEntriesKeyValue = sortedEntries.map((version, index) => [index, version]);
  const sortedEntryMap = new Map(sortedEntriesKeyValue);
  const sortedObject = Object.fromEntries(sortedEntryMap);
  return sortedObject;
};





export { calculateManualVersions, getBook, allReleaseVersions, getAllValues }
